.masthead {
  background-attachment: fixed;
  color: $color-background;
  text-align: center;
  padding: $lead-quadruple $lead-single $lead-triple;

  .logo {
    max-height: $lead-single * 5;
  }
}

.row {
  padding: $lead-double 0;

  &--lean {
    padding: $lead-single 0;
  }

  &.dark {
    background: $color-background !important;
  }

  &.dark2 {
    background: $color-background-dark !important;
  }

  &.darker {
    background: $secondary !important;
    color: $color-background !important;

    blockquote,
    .text,
    .author {
      color: $color-background-dark !important;
    }
  }
}

.content .row.shave-bottom [class*="col-"] *:last-child {
  margin-bottom: 0;
}

.big-cta {
  color: #fff;
}

.cta {
  &-text--left {
    text-align: center;
  }

  &__title {
    @extend .h2;
    line-height: $lead-single * 1.5;
    font-weight: $weight-normal;
    margin: $lead-single 0 $lead-triple;
  }

  &__form {
    padding-top: $lead-double;
    border-top: 1px solid $color-border;
  }
}

.section-title {
  margin-bottom: $lead-triple;

  &__small {
    margin-bottom: $lead-double;
  }
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.input {
  color: $color-text;
  padding: 12px $lead-half;
  border: 0;
  border-radius: $border-radius;
  margin-right: $lead-half;
  width: 100%;

  &-pair {
    &--left {
      margin-bottom: $lead-single;
    }

    &--right {
      .btn {
        padding: 15px $lead-half 16px;
      }
    }
  }
}

.icon-panel {
  display: flex;
  flex-wrap: wrap;

  &__item {
    width: 100%;
    margin-bottom: $lead-triple;
    padding: 0 $lead-single;
  }

  &__image {
    text-align: center;
    font-size: $type-scale-4;
    color: $primary;
    margin-bottom: $lead-single;

    i {
      width: $font-size * 2;
      height: $font-size * 2;
      display: inline-block;
      vertical-align: middle;
    }
  }

  &__title {
    text-align: center;
    margin-bottom: $lead-single;
  }

  p {
    text-align: center;
  }
}

.social-proof,
.social-proof--design {
  overflow: hidden;
  min-height: 90vh;
  padding: 0;
  position: relative;

  blockquote,
  .quote {
    margin-bottom: 0;
    opacity: 0;
    transition: opacity 1s ease-out;
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding: $lead-single;
    text-overflow: ellipsis;

    .inner,
    .inner p {
      max-height: 75%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.visible {
      opacity: 1;
      transition: opacity 0.8s ease-in;
    }
  }

  &:before {
    content: open-quote;
    font-size: $type-scale-5;
    position: absolute;
    display: none;
    top: 40%;
    left: 10vh;
    color: rgba($color-dark, 0.25);
    border: 1px solid rgba($color-dark, 0.25);
    padding: 30px 16px 0px;
    line-height: 12px;
    border-radius: 50%;
    width: 57px;
    height: 57px;
  }
}

.social-proof--design {
  height: auto;

  .quote .inner {
    overflow: visible;
  }
}

[class*="socicon"] {
  text-decoration: none;
}

.social-media {
  a {
    padding: $lead-half;
  }
}

.classy-container {
  $classy-border: 1px solid $color-text-lighter;

  border: $classy-border;
  border-radius: $border-radius;
  padding: ($lead-single + 7px) $lead-single ($lead-single + 4px);
  position: relative;
  margin: $lead-single 0;

  &:after {
    $inner-offset: 6px;

    content: '';
    display: block;
    position: absolute;
    left: $inner-offset;
    top: $inner-offset;
    right: $inner-offset;
    bottom: $inner-offset;
    border: $classy-border;
    border-radius: $border-radius;
    pointer-events: none;
  }
}

.price-block {
  margin: $lead-single 0 $lead-double;
  font-size: $type-scale-4;
  text-align: center;

  .currency {
    color: $color-text;
  }

  .suffix {
    text-decoration: underline;
  }

  .prefix,
  .suffix {
    font-size: 0.5em;
    margin: 0 0.25em;
    position: relative;
    top: -0.6em;
    color: $color-text-lighter;
  }
}

.copyright {
  text-align: center;

  .icon-repeat {
    font-size: 3em;
    margin: 0 0px;
    position: relative;
    top: 14px;
    opacity: 0.4;
  }
}

.warning,
.note {
  border: 4px solid $ruby;
  padding: $lead-single;
  border-radius: $border-radius;
  margin-bottom: $lead-single;

  .admonition-title {
    @extend h3;
    text-align: center;
    margin-bottom: $lead-single;

    &:before {
      margin-right: $lead-half;
      font-size: 2rem;
      position: relative;
      top: 0.5rem;
    }
  }

  .last {
    margin-bottom: $lead-half;
  }
}

.warning {
  border-color: $ruby;

  .admonition-title {
    color: $ruby;
  }
}

.note {
  border-color: $c;

  .admonition-title {
    color: $c;
  }
}

.bio {
  &__image {
    border-radius: 50%;
    max-width: 120px;
    margin-bottom: $lead-single;
  }
}

.design-layout {
  blockquote p,
  .quote p {
    &:after {
      content: '';
    }
  }
}
