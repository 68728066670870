.btn {
  text-transform: uppercase;
  font-weight: $weight-bold;
  letter-spacing: 0.05rem;
  padding: $lead-half;
  text-decoration: none;
  border: none;
  white-space: normal;

  &-primary,
  &-primary:active,
  &-primary:focus,
  &-primary:active:focus {
    padding: $lead-half $lead-single;
    background: $primary;
    color: #fff !important;

    &:hover {
      background: darken($primary, 5%);
    }
  }

  &-secondary {
    border: 2px solid $primary;
    background-color: transparent;

    &:hover {
      border-color: darken($primary, 5%);
    }

    &:active,
    &:focus,
    &:active:focus {
      box-shadow: none;
    }
  }

  &-white {
    border-color: #fff !important;
    color: #fff !important;
    transition: $transition-in;

    &:hover {
      border-color: #fff !important;
      background: #fff;
      color: $design-dark !important;
      transition: $transition-out;
    }
  }

  &-full {
    width: 100%;
    margin-bottom: $lead-double;

    &-xs {
      width: 100%;
    }
  }

  &-subtext {
    margin-top: -($lead-single);
  }

  &-pad-single {
    padding: $lead-half !important;
  }
}
