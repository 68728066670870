.code {           @include brand('code'); }
.python {         @include brand('python'); }
.more-python {    @include brand('more-python'); }
.ruby {           @include brand('ruby'); }
.c {              @include brand('c'); }
.javascript {     @include brand('javascript'); }
.sql {            @include brand('sql'); }
.unix {           @include brand('unix'); }
.regex {        @include brand('regex'); }
.design {         @include brand('design'); }
