.header {
  z-index: 500;
  position: absolute;
  width: 100%;
}

.navbar {
  border-radius: 0;
  margin-bottom: 0;
  border: none;
  min-height: inherit;

  a:hover {
    text-decoration: none;
  }
}

.nav-main {
  background-color: rgba($color-background-light, 1);
  border-bottom: 1px solid $color-border;
}

.nav-brand-family {
  color: $color-text-nav;
  @extend .small;

  &:before {
    background-color: $secondary;
    background-image: -webkit-linear-gradient(310deg, $gradientStart 0%, $gradientEnd 100%);
    background-image: -o-linear-gradient(310deg, $gradientStart 0%, $gradientEnd 100%);
    background-image: linear-gradient(40deg, $gradientStart 0%, $gradientEnd 100%);
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.35;
  }

  .row {
    padding: 0;
  }

  span {
    margin-right: 0.75rem;
  }

  a {
    color: $color-text-nav;
    padding: 6px 10px 7px;
    display: inline-block;
    background-color: none;
    text-decoration: none;

    &.active,
    &:hover {
      color: $color-text-nav-hover;
      background-color: rgba($color-dark, 0.5);
    }
  }

  li {
    float: left;
  }

  .site-meta {
    li {
      float: right;
    }
  }

  .cta {
    background: $color-link;
    color: darken(#fff, 5%);
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    font-weight: bold;

    &:hover {
      background: lighten($color-link, 2%);
      color: #fff;
    }
  }

  [class*="user"]:before {
    position: relative;
    top: 2px;
    margin-right: 4px;
  }
}

.grad-0 { color: $gradientStart; }
.grad-1 { color: $gradient1; }
.grad-2 { color: $gradient2; }
.grad-3 { color: $gradientEnd; }

nav {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
      padding: 0;
    }
  }
}

.nav {
  &-inline {
    text-align: left;

    li {
      display: inline-block;
      margin: 0;
      padding: 0;

      a {
        padding: $lead-single $lead-half;
        display: inline-block;

        &:hover {
          background: darken($color-background-dark, 5%);
        }
      }
    }
  }
}
