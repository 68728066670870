.book-site {
  .overlay {
    background: $color-overlay;
    width: 100%;
    height: 100%;
    z-index: 100;
    opacity: 0;
    transition: opacity 0.5s ease-out;
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 0;
  }

  .wrapper {
    display: flex;
    align-items: stretch;
    position: relative;
    overflow: hidden;
    flex-direction: column;

    &[class*="is-open"] {
      .overlay {
        opacity: 1;
        transition: opacity 0.35s ease-out;
        transition-delay: 0.05s;
        pointer-events: auto;
      }

      .sidebar {
        transform: translate( $line-height * 10, 0 );
        transition: transform 0.25s ease-out;
      }

      .main {
        transform: translate( $line-height, 0 );
        transition: transform 0.25s ease-out;
      }
    }
  }

  nav.side-nav-container {
    z-index: 2;

    a {
      padding: $lead-half 1.15rem;
      display: block;
      color: $color-text-light;

      [class*="icon-"] {
        font-size: 1rem;
        position: relative;
        top: 0.05rem;
        margin-right: $lead-half + 2;
      }

      &:hover {
        color: $color-text;
        background: darken($color-background-light, 5%);
      }
    }
  }

  .sidebar {
    width: $line-height * 10;
    height: 200vh;
    position: absolute;
    z-index: 120;
    top: 0;
    right: 100%;
    transform: translate( 0, 0 );
    transition: all 0.2s ease-out;
    background: $color-background-light;
    // Grid for dev purposes
    // background-image: linear-gradient(90deg, $grid-1 0%, $grid-1 5%, $grid-2 5%, $grid-2 11%, $grid-1 11%, $grid-1 20%, $grid-2 20%);

    &--fixed {
      position: fixed;
      overflow-y: scroll;
      height: 100vh;

      .side-nav-container {
        padding-top: 6.65rem;
      }
    }

    h3 {
      @extend .small;
      padding: $lead-half;
      margin: 0;
      background: $color-dark;
      color: $color-background-light;
      text-indent: 2.55rem;

      i {
        margin-right: $lead-half;
      }
    }
  }

  .table-of-contents {
    margin-bottom: 0;

    &.top-offset {
      padding-top: 150px;
    }

    li {
      margin: 0;
      padding: 0;
      position: relative;

      &:before {
        width: $line-height;
        height: $line-height;
        margin-left: $lead-half;
        display: inline-block;
        position: absolute;
        left: 0;
        top: 1.25em;
        line-height: 2;
      }

      a {
        padding-left: $lead-double;
      }
    }
  }

  .site-links {
    h3 {
      margin-top: 0;
    }
  }

  .nav-trigger {
    padding: (($lead-single * 0.75)-1px) $lead-half ($lead-single * 0.75);
    color: $color-text-light;
    height: 66px;

    &#toc {
      padding-right: $lead-single - 3px;
    }

    &#help {
      padding-left: $lead-single;
    }

    &_text {
      margin-left: $lead-half + 2;
    }

    &:hover,
    &:active,
    &.active {
      color: $color-text;
      background: rgba($color-dark, 0.04);
    }

    &.active {
      .bar {
        background: $color-text;
      }

      // active for both hamburcloser and questioncloser
      .transformer {
        color: $color-text;
      }

      // active hamburcloser
      .hamburcloser {
        .bar-m-a,
        .bar-m-b {
          transition-delay: 0.15s;
          transition: transform 0.35s ease-out;
        }

        .bar-t,
        .bar-b {
          transition: transform 0.15s ease-out;
        }

        .bar-t {
          transform: translate(0, 7px) scaleX(0);
        }

        .bar-b {
          transform: translate(0, -7px) scaleX(0);
        }

        .bar-m-a {
          transform: rotate(135deg);
        }

        .bar-m-b {
          transform: rotate(225deg);
        }
      }

      // active questioncloser
      .questioncloser {
        .icon-question-mark {
          transform: rotate(135deg) scale(0);
          transition: transform 0.15s ease-out;
        }

        .bar-m-a,
        .bar-m-b {
          transition-delay: 0.15s;
          transition: all 0.35s ease-out;
          opacity: 1;
        }

        .bar-m-a {
          transform: rotate(135deg);
        }

        .bar-m-b {
          transform: rotate(225deg);
        }
      }
    }
  }

  .transformer {
    position: relative;
    float: left;
    width: $font-size;
    height: $font-size;
    color: $color-text-light;

    .bar {
      width: $font-size;
      height: 1px;
      background: $color-text-light;
      position: absolute;
      transition: transform 0.2s ease-in;
    }

    .bar-t {
      top: 0;
    }

    .bar-m-a,
    .bar-m-b {
      top: 50%;
    }

    .bar-b {
      top: 100%;
    }
  }

  .hamburcloser {
    margin-top: 6px;
    height: $font-size * 0.75;
  }

  .questioncloser {
    margin-top: 3px;
  }

  .colophon {
    .intro {
      padding: ($lead-single - 1px) $lead-half $lead-single;
      border-top: 1px solid $color-border;
      background: darken($color-background-light, 5%);
      margin-bottom: 0;
    }
  }

  .printers-mark {
    position: relative;
    padding: $lead-half $lead-half;
    background: $secondary;
    color: $color-text-light;

    .inscription {
      @extend .small;
      font-family: $display-stack;
      display: inline-block;
      color: lighten($color-text-light, 20%);
    }

    [class*="icon-"] {
      font-size: 1.4rem;
      top: 4px;
      position: relative;
      line-height: $line-height;
      margin: 0 $lead-half;
    }
  }
}
