@charset "UTF-8";

@font-face {
  font-family: "learn-code-the-hard-way";
  src:url("../fonts/learn-code-the-hard-way.eot");
  src:url("../fonts/learn-code-the-hard-way.eot?#iefix") format("embedded-opentype"),
    url("../fonts/learn-code-the-hard-way.woff") format("woff"),
    url("../fonts/learn-code-the-hard-way.ttf") format("truetype"),
    url("../fonts/learn-code-the-hard-way.svg#learn-code-the-hard-way") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "learn-code-the-hard-way" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before,
.admonition-title:before {
  font-family: "learn-code-the-hard-way" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-video:before {
  content: "\61";
}
.icon-document:before {
  content: "\63";
}
.icon-user:before {
  content: "\6b";
}
.icon-locked:before {
  content: "\62";
}
.icon-unlocked:before {
  content: "\65";
}
.icon-search:before {
  content: "\66";
}
.icon-email:before {
  content: "\67";
}
.icon-upload:before {
  content: "\68";
}
.icon-pants:before {
  content: "\69";
}
.icon-delete:before {
  content: "\6c";
}
.icon-checkmark:before {
  content: "\6a";
}
.icon-notice:before {
  content: "\6d";
}
.warning .admonition-title:before,
.icon-warning:before {
  content: "\6e";
}
.note .admonition-title:before,
.icon-information:before {
  content: "\6f";
}
.icon-question-mark:before {
  content: "\70";
}
.icon-repeat:before {
  content: "\64";
}
.icon-arrow-left:before {
  content: "\71";
}
.icon-arrow-right:before {
  content: "\72";
}
.icon-arrow-down:before {
  content: "\73";
}
.icon-arrow-up:before {
  content: "\74";
}
.icon-arrow-left-double:before {
  content: "\75";
}
.icon-arrow-right-double:before {
  content: "\76";
}
.icon-arrow-up-double:before {
  content: "\77";
}
.icon-arrow-down-double:before {
  content: "\78";
}
.icon-content:before {
  content: "\79";
}
.icon-affordances:before {
  content: "\7a";
}
.icon-typography:before {
  content: "\41";
}
.icon-layout:before {
  content: "\42";
}
.icon-color:before {
  content: "\43";
}
.icon-imagery:before {
  content: "\44";
}
.icon-book:before {
  content: "\45";
}
.icon-vector:before {
  content: "\46";
}
