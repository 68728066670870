@function brandColor( $lang ) {
  @return $lang;
}

@mixin brand( $lang ) {
  $base: map-get($brand, $lang);
  $dark: map-get($brand-dark, $lang);

  &-background {
    background-color:       $base;
    background-image:       url('../images/pattern-#{$lang}.svg');
    background-size:        200px 200px;
  }

  a,
  a:visited {
    color: $base;

    &:hover,
    &:active,
    &:focus {
      color: $dark;
    }
  }

  .btn-primary {
    background-color: $base;

    &:hover,
    &:active,
    &:focus,
    &:active:focus:hover,
    &:active:hover,
    &:focus:hover {
      background-color: $dark;
    }
  }

  .btn-secondary {
    border-color: $base;

    &:hover,
    &:active,
    &:focus,
    &:active:focus:hover,
    &:active:hover,
    &:focus:hover {
      border-color: $dark;
    }
  }

  .icon-panel__image {
    color: $base;
  }
}
